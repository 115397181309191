<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <page-top-title>
              <template v-slot:toptitle-icon>mdi-dna</template>
              <template v-slot:toptitle-text>Data Matrix Keys</template>
            </page-top-title>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="matrixKeys"
          :search="search"
          sort-by="key"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nueva Matrix Key
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.key"
                            label="Clave"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.old_key"
                            label="Clave Antigua"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Eliminar la clave?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import PageTopTitle from "@/components/mylogy/PageTopTitle";
export default {
  name: "DataMatrixKeys",
  components: { PageTopTitle },
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Clave",
        align: "start",
        sortable: true,
        value: "key"
      },
      { text: "Acciones", value: "actions", sortable: false }
    ],
    matrixKeys: [],
    editedIndex: -1,
    editedItem: {
      key: null,
      old_key: null,
      _id: null
    },
    defaultItem: {
      key: null,
      old_key: null
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Clave" : "Editar Clave";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let self = this;
      fetch(
        `https://strapi.mylogy.xyz/data-matrix-keys?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9&_limit=1000`
      ).then(function(response) {
        response.json().then(function(data) {
          self.matrixKeys = data;
        });
      });
    },

    editItem(item) {
      this.editedIndex = this.matrixKeys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.matrixKeys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let self = this;
      fetch(
        `https://strapi.mylogy.xyz/data-matrix-keys/${self.editedItem._id}?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`,
        {
          method: "DELETE"
        }
      ).then(function(response) {
        response.json().then(function() {
          self.matrixKeys.splice(self.editedIndex, 1);
          self.closeDelete();
        });
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let self = this;
      if (self.editedIndex > -1) {
        fetch(
          `https://strapi.mylogy.xyz/data-matrix-keys/${self.editedItem._id}?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`,
          {
            method: "PUT",
            body: JSON.stringify(self.editedItem),
            headers: { "Content-type": "application/json; charset=UTF-8" }
          }
        ).then(function(response) {
          response.json().then(function(data) {
            Object.assign(self.matrixKeys[self.editedIndex], data);
            self.close();
          });
        });
      } else {
        fetch(
          `https://strapi.mylogy.xyz/data-matrix-keys?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`,
          {
            method: "POST",
            body: JSON.stringify(self.editedItem),
            headers: { "Content-type": "application/json; charset=UTF-8" }
          }
        ).then(function(response) {
          response.json().then(function(data) {
            self.matrixKeys.push(data);
            self.close();
          });
        });
      }
    }
  }
};
</script>
